import { BAC_ADMIN_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, CREATIVE_ROLE, LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE, SOCIAL_REVIEWER_ROLE, GAMES_REVIEWER_ROLE_REVIEWER_ROLE } from '@/constants/ba-roles.js';
import { BAC_FINAL_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP, CREATIVE_REVIEW_STEP, LEGAL_REVIEW_STEP, LICENSEE_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP } from '@/constants/submissions.js';
import { computed, ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useSubmissionLoadTracker from './submissionLoadTracker.js';

/**
 ** list of submissions
 ** schema of a submission tab item- { submisionId: String, licenseeName: String }
 */
const submissionsTabList = ref([]);
const persistedSubmissionId = ref('');

export default () => {
    const store = useStore();
    const { submissionForm, resetSubmissionForm, resetSubmissionFormValidations, updateSubmissionForm } = useSubmissionForm();
    const { updateSubmissionLoadingTracker } = useSubmissionLoadTracker();
    const __submissionLoadingTracker = reactive({
        isOpeningSubmission: false
    });

    /* ------------------------- submission tabs logic ------------------------- */
    const selectedSubmissionTabId = computed(() => submissionForm.submissionId); // submissionId of currently opened submission
    /**
     * updates submisions in submissionsTabList, keeping each submission unique, to avoid opening duplicate submissions
     * @param {Array} submissionsToBeOpened
     */

    const gridSubmissionsList = computed(() => store.getters['baSubmissions/getSubmissionsList']);
    const updateSubmissionsTabList = (submissionsToBeOpened, submitNxtBool = false) => {
        if (submitNxtBool) {
            const __alreadyOpenTabIds = submissionsTabList.value.map(s => s.submissionId) || [];
            const __toBeOpenedTabIds = submissionsToBeOpened.map(s => s.submissionId) || [];

            const __untouched = submissionsTabList.value.filter(s => !__toBeOpenedTabIds.includes(s.submissionId));
            const __toBeSwitched = gridSubmissionsList.value.data.filter(s => __toBeOpenedTabIds.includes(s.submissionId));
            const __toBeOpened = submissionsToBeOpened.filter(s => !__alreadyOpenTabIds.includes(s.submissionId));

            submissionsTabList.value = [
                ...__untouched,
                ...__toBeOpened,
                ...__toBeSwitched // Keep these last .. so that the last one in the list will be switched to, as part of "initModalContent"
            ];
        } else {
            if (submissionsTabList.value.length > 0) {
                const __alreadyOpenTabIds = submissionsTabList.value.map(s => s.submissionId) || [];
                const __toBeOpenedTabIds = submissionsToBeOpened.map(s => s.submissionId) || [];

                const __untouched = submissionsTabList.value.filter(s => !__toBeOpenedTabIds.includes(s.submissionId));
                const __toBeSwitched = submissionsTabList.value.filter(s => __toBeOpenedTabIds.includes(s.submissionId));
                const __toBeOpened = submissionsToBeOpened.filter(s => !__alreadyOpenTabIds.includes(s.submissionId));

                submissionsTabList.value = [
                    ...__untouched,
                    ...__toBeOpened,
                    ...__toBeSwitched // Keep these last .. so that the last one in the list will be switched to, as part of "initModalContent"
                ];
            } else {
                // otherswise open all submissions
                submissionsTabList.value = [...submissionsToBeOpened];
            }
        }
    };

    /* ----------------------- submission details logic --------------------- */
    const loadedSubmissions = computed(() => store.getters['baSubmissions/getOpenedSubmissions'] || []); // cached list of submissions that are already fetched from submission details service
    /**
     * loads submission details for submission form
     * @param {String} submissionId
     */
    const openSubmissionDetails = async ({ submissionId, assignUser, submissionStatus, currentStepName }) => {
        try {
            const userRoles = store.getters['auth/getUserRoles'] || [];
            __submissionLoadingTracker.isOpeningSubmission = true;
            console.log(`Fetching latest details for submission ${submissionId}`);
            const fetchedSubmission = await store.dispatch('baSubmissions/fetchSubmissionDetails', { submissionId, assignUser, submissionStatus, currentStepName });

            setSubmissionForm(submissionId);

            /* NOTE: Disable User Role checking

            // Check for the Roles .. the user should have the permission to access the submission at the current step
            // If the permission is not satisfied .. RESET the submissionForm .. call resetSubmissionForm()
            // ELSE continue
            let stepToRole;
            if (fetchedSubmission.currentStepName === BAC_FINAL_STEP) {
                stepToRole = BAC_ADMIN_ROLE;
            } else if (fetchedSubmission.currentStepName === BAC_REVIEW_STEP) {
                stepToRole = BAC_REVIEWER_ROLE;
            } else if (fetchedSubmission.currentStepName === LICENSEE_STEP) {
                stepToRole = LICENSEE_ROLE;
            } else if (fetchedSubmission.currentStepName === LEGAL_REVIEW_STEP) {
                stepToRole = LEGAL_ROLE;
            } else if (fetchedSubmission.currentStepName === CREATIVE_REVIEW_STEP) {
                stepToRole = CREATIVE_ROLE;
            } else if (fetchedSubmission.currentStepName === SAFETY_STEP) {
                stepToRole = SAFETY_ROLE;
            } else if (fetchedSubmission.currentStepName === SOCIAL_REVIEW_STEP) {
                stepToRole = SOCIAL_REVIEWER_ROLE;
            } else if (fetchedSubmission.currentStepName === BAC_SUPERVISOR_STEP) {
                stepToRole = BAC_SUPERVISOR_ROLE;
            }

            if (!userRoles.includes(stepToRole)) {
                persistedSubmissionId.value = submissionId;
                resetSubmissionForm();
                resetSubmissionFormValidations();
            } else {
                setSubmissionForm(submissionId);
            }
            */
        } catch (err) {
            console.error(err);
        } finally {
            __submissionLoadingTracker.isOpeningSubmission = false;
        }
    };
    /**
     * updates submission form with details of specified submission
     * @param {String} submissionId
     */
    const setSubmissionForm = (submissionId) => {
        const matchedSubmission = loadedSubmissions.value.find(submission => submission.submissionId === submissionId);
        if (matchedSubmission) updateSubmissionForm({ ...matchedSubmission }, true);
    };
    /**
     * saves unsaved changes made in submission form by user in store, used while switching submissions to persist data
     */
    const saveCurrentSubmission = async () => {
        await store.dispatch('baSubmissions/saveOpenedSubmission', {
            submission: { ...submissionForm }
        });
        resetSubmissionForm();
        resetSubmissionFormValidations();
    };

    /* ------------------------- reset submission form modal logic ------------------------- */
    /**
     * resets global state of submissionFormModal hook
     */
    const resetSubmissionFormModal = () => {
        submissionsTabList.value = [];
        __submissionLoadingTracker.isOpeningSubmission = false;
    };

    watch(
        __submissionLoadingTracker,
        () => {
            updateSubmissionLoadingTracker({ ...__submissionLoadingTracker });
        }
    );

    return {
        submissionsTabList,
        updateSubmissionsTabList,
        selectedSubmissionTabId,
        loadedSubmissions,
        openSubmissionDetails,
        saveCurrentSubmission,
        resetSubmissionFormModal,
        gridSubmissionsList,
        persistedSubmissionId
    };
};
